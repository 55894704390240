<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-card class="py-5">
      <v-card-title class="justify-center">
        <!-- {{ this.$route.params.id ? $t('settings.branch.editBranch') : $t('settings.branch.addBranch') }} -->
        <div>
          <v-img
            src="../../../assets/logo-login.png"
          />
        </div>
      </v-card-title>
      <template>
        <v-form
          v-model="valid"
          @submit.prevent="submitForm()"
        >
          <v-container fluid>
            <v-row class="mx-md-16 px-md-16">
              SYSTEM SETTINGS
              <v-col
                cols="12"
              >
                <label>
                  {{ $t('options.website') }}
                </label>
                <v-text-field
                  v-model="data.website"
                  outlined
                  :label="$t('options.website')"
                  required
                />
              </v-col>
              <v-col
                cols="12"
              >
                {{ $t('options.address') }}
                <v-text-field
                  v-model="data.address"
                  required
                  :label="$t('options.address')"
                  outlined
                />
              </v-col>
              <v-col
                cols="12"
              >
                {{ $t('options.phone') }}
                <v-text-field
                  v-model="data.phone"
                  required
                  :label="$t('options.phone')"
                  outlined
                />
              </v-col>
              <v-col
                cols="12"
              >
                {{ $t('options.dollarPrice') }}
                <v-text-field
                  v-model="data.dollar_price"
                  required
                  :label="$t('options.dollarPrice')"
                  type="number"
                  outlined
                />
              </v-col>
            </v-row>
            <v-btn
              type="submit"
              class="mx-auto my-auto d-flex"
              color="indigo"
              :loading="loading"
              :disabled="disabled"
            >
              {{ $t('actions.save') }}
            </v-btn>
          </v-container>
        </v-form>
      </template>
    </v-card> -->
    <v-snackbar
      v-model="successSnackbar"
      color="success"
      shaped
      absolute
      bottom
      right
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      absolute
      bottom
      right
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
  import { ServiceFactory } from '@/services/ServiceFactory'
  const optionsService = ServiceFactory.get('options')

  export default {
    name: 'Companies',
    data: (vm) => ({
      dataLoading: false,
      valid: false,
      data: {
        address: '',
        dollar_price: null,
        phone: '',
        website: '',
      },
      successSnackbar: false,
      errorSnackbar: false,
      timeout: 3000,
      successMessage: '',
      errorMessage: '',
      loading: false,
      disabled: false,
    }),
    created () {
      this.fetchData()
    },
    methods: {
      async  submitForm () {
        this.loading = true
        this.disabled = true
        const formData = {
          address: this.data.address,
          dollar_price: +this.data.dollar_price,
          phone: this.data.phone,
          website: this.data.website,
        }
        this.updateContent(formData)
      },
      async updateContent (data) {
        const item = await optionsService.AddData(data)
        try {
          if (item.status === 'success') {
            this.successMessage = 'Item Updated Successfuly'
            this.successSnackbar = true
            setTimeout(() => {
              this.successSnackbar = false
            }, 1500)
          } else {
            this.errorMessage('something Error')
            this.errorSnackbar = true
            setTimeout(() => {
              this.fetchData()
              this.errorSnackbar = false
            }, 1500)
          }
        } catch (err) {
          console.log('err', err)
          this.errorMessage('something Error')
          this.errorSnackbar = true
          setTimeout(() => {
            this.fetchData()
            this.disabled = false
            this.loading = false
          }, 1500)
        }
        this.disabled = false
        this.loading = false
      },
      async fetchData () {
        this.dataLoading = true
        const item = await optionsService.fetchData()
        console.log('item', item)
        this.data = item[0]
        this.dataLoading = false
      },
    },
  }
</script>
<style>
a{
  text-decoration: none;
}
</style>
